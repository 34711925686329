import {
  CURRENT_WINNER,
  EVENT_DATA_ITEM_STATES,
  TBrokerMessageData,
} from 'src/common/services/clients/websockets/types';
import { LOT_BIDDERS } from '../board-footer';
import { LOT_STATUSES } from '../lot-status';
import { IToteBoardProps } from './types';

/**
 * A helper that return tote board props from the event data. If a new online bid is submitted,
 * lotBidder is set to Online and bidAmount is set to the submittedBid. If the bid is accepted
 * bidAmount is set to the highest bid and lotBidder is set to Floor in case of floor bidder or
 * is set to paddle number if it is available otherwise 0.
 * @param eventData : TBrokerMessageData { lotNumber, itemState, currentWinner, submittedBid, currentBid, paddle }
 * @returns {IToteBoardProps} Tote board props object
 */
export const getToteBoardPropsFromSocketEventData = (eventData: TBrokerMessageData): IToteBoardProps => {
  const { lotNumber, itemState, currentWinner, submittedBid, currentBid, paddle, submittedBidPaddle } =
    eventData?.items[0];

  const toteBoardProps: IToteBoardProps = { lotNumber: lotNumber, lotBidder: undefined };

  toteBoardProps.lotStatus = itemState === EVENT_DATA_ITEM_STATES.SOLD ? LOT_STATUSES.SOLD : undefined;
  if (submittedBid && itemState !== EVENT_DATA_ITEM_STATES.SOLD) {
    toteBoardProps.bidAmount = parseInt(submittedBid);
    toteBoardProps.lotBidder = parseInt(submittedBidPaddle) ? parseInt(submittedBidPaddle) : LOT_BIDDERS.ONLINE;
    toteBoardProps.isOnlineBidSubmitted = true;
  } else {
    toteBoardProps.bidAmount = parseInt(currentBid);
    if (currentWinner === CURRENT_WINNER.ONLINE) {
      toteBoardProps.lotBidder = paddle ? parseInt(paddle) : 0;
    } else if (currentWinner === CURRENT_WINNER.FLOOR) {
      toteBoardProps.lotBidder = LOT_BIDDERS.FLOOR;
    }
  }

  return toteBoardProps;
};
