import { useEffect, useState } from 'react';
import { ENV } from '../../common/configs/env';
import WebSocketClient from '../../common/services/clients/websockets';
import {
  AUCTION_STATES,
  LOT_ITEM_MESSAGE_TOKENS,
  TBrokerMessage,
  TBrokerMessageData,
  WEB_SOCKET_EVENTS,
} from '../../common/services/clients/websockets/types';
import { LOT_STATUSES } from '../components/lot-status';
import { IToteBoardProps } from '../components/toteboard';
import { getToteBoardPropsFromSocketEventData } from '../components/toteboard/helpers';

/**
 * A custom hook that depending on the latest event received returns the Tote board props
 * @param catalogRef : string
 * @return {IToteBoardProps} : Tote board props to be rendered
 * { lotNumber, itemState, currentWinner, submittedBid, currentBid, paddle }
 */
export function useWebSocketEvents(catalogRef: string) {
  const [lotDataToBeDisplayed, setLotDataToBeDisplayed] = useState({} as IToteBoardProps);
  const [, setLotDataToBeDisplayedAfterDelay] = useState({} as IToteBoardProps);
  const [, setIsDelayed] = useState(false);

  /**
   * This function set delay and sets the latest lot data to be used in the ToteBoard component
   * after the delay.
   * @param eventData : TBrokerMessageData { lotNumber, itemState, currentWinner, submittedBid, currentBid, paddle }
   */
  const setDelayAndTimeoutCallback = (eventData: TBrokerMessageData) => {
    setIsDelayed(true);
    setLotDataToBeDisplayedAfterDelay(getToteBoardPropsFromSocketEventData(eventData));
    setTimeout(() => {
      setIsDelayed(false);
      setLotDataToBeDisplayedAfterDelay(updatedLotDataToBeDisplayed => {
        setLotDataToBeDisplayed(updatedLotDataToBeDisplayed);
        return {};
      });
    }, ENV.TOTE_BOARD_LOT_SELL_PASS_DELAY_MS);
  };

  /**
   * Callback that is to be called when a new event is received from the WebSocket
   * @param parsedData: TBrokerMessage { event, data }
   */
  const webSocketEventHandler = (parsedData: TBrokerMessage) => {
    let setLatestPropsIfDelayed = false;
    setIsDelayed(isPrevStateDelayed => {
      if (isPrevStateDelayed) {
        setLatestPropsIfDelayed = true;
        setLotDataToBeDisplayedAfterDelay(getToteBoardPropsFromSocketEventData(parsedData.data));
      }
      return isPrevStateDelayed;
    });
    if (setLatestPropsIfDelayed) return;

    switch (parsedData.event) {
      case WEB_SOCKET_EVENTS.SELL:
        setLotDataToBeDisplayed(getToteBoardPropsFromSocketEventData(parsedData.data));
        setDelayAndTimeoutCallback(parsedData.data);
        break;

      case WEB_SOCKET_EVENTS.NEXT_UNSOLD:
        if (parsedData.data.items[0].messageToken === LOT_ITEM_MESSAGE_TOKENS.PASSED) {
          setLotDataToBeDisplayed(prevLotDataToBeDisplayed => {
            return {
              ...prevLotDataToBeDisplayed,
              lotStatus: LOT_STATUSES.UNSOLD,
            };
          });
          setDelayAndTimeoutCallback(parsedData.data);
        }
        break;

      case WEB_SOCKET_EVENTS.LOT:
      case WEB_SOCKET_EVENTS.UNDO_BID:
      case WEB_SOCKET_EVENTS.BID_SUBMIT:
      case WEB_SOCKET_EVENTS.UNDO_SELL:
      case WEB_SOCKET_EVENTS.ASK:
        setLotDataToBeDisplayed(getToteBoardPropsFromSocketEventData(parsedData.data));
        break;

      default:
        if (parsedData.data.auctionState === AUCTION_STATES.CLOSED) WebSocketClient.terminateSocket();
        break;
    }
  };

  useEffect(() => {
    WebSocketClient.setOnConnectEventHandler(webSocketEventHandler, catalogRef);
  }, [catalogRef]);

  useEffect(() => {
    return () => {
      WebSocketClient.terminateSocket();
    };
  }, []);

  return lotDataToBeDisplayed;
}
